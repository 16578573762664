// 常量路由
export const constantRoutes = [
  {
    path: '/',
    redirect: '/home',
    meta: {
      hidden: true
    }
  },
  {
    path: '/login',
    meta: {
      hidden: true
    },
    component: () => import('@/views/login/index')
  },
  {
    path: '/home',
    name: 'Home',
    meta: {
      icon: 'el-icon-s-home',
      name: '首页',
      affix: true
    },
    component: () => import('../views/home/index.vue')
  }
]

// 任意路由
export const anyRoutes = [
  //
]

// 动态路由
export const asyncRoutes = () => [
  {
    path: '/userManage',
    name: 'UserManage',
    component: () => import('@/views/userManage/index.vue'),
    meta: { name: '用户管理', icon: 'el-icon-user-solid' },
    children: [
      {
        path: '/userManage/black/list',
        name: 'BlackList',
        component: () => import('@/views/userManage/black-list/list.vue'),
        meta: { name: '黑名单列表', keepAlive: false }
      },
      {
        path: '/userManage/black/add',
        name: 'BlackAdd',
        component: () => import('@/views/userManage/black-list/addOrEdit.vue'),
        meta: { name: '新增黑名单', hidden: true, activeMenu: '/userManage/black/list' }
      },
      {
        path: '/userManage/black/edit/:id?',
        name: 'BlackEdit',
        component: () => import('@/views/userManage/black-list/addOrEdit.vue'),
        meta: { name: '编辑黑名单', hidden: true, activeMenu: '/userManage/black/list' }
      },
      {
        path: '/userManage/gray/list',
        name: 'GrayList',
        component: () => import('@/views/userManage/gray-list/list.vue'),
        meta: { name: '灰名单列表', keepAlive: false }
      },
      {
        path: '/userManage/gray/add',
        name: 'GrayAdd',
        component: () => import('@/views/userManage/gray-list/addOrEdit.vue'),
        meta: { name: '新增灰名单', hidden: true, activeMenu: '/userManage/gray/list' }
      },
      {
        path: '/userManage/gray/edit/:id?',
        name: 'GrayEdit',
        component: () => import('@/views/userManage/gray-list/addOrEdit.vue'),
        meta: { name: '编辑灰名单', hidden: true, activeMenu: '/userManage/gray/list' }
      },
      {
        path: '/userManage/hit/list',
        name: 'HitList',
        component: () => import('@/views/userManage/hit-list/list.vue'),
        meta: { name: '撞库查询', keepAlive: true }
      }
    ]
  },
  {
    path: '/powerManage',
    name: 'PowerManage',
    redirect: '/powerManage/user/list',
    component: () => import('@/views/powerManage/index.vue'),
    meta: { name: '权限管理', icon: 'el-icon-s-check' },
    children: [
      {
        path: '/powerManage/user/list',
        name: 'UserList',
        component: () => import('@/views/powerManage/user/list.vue'),
        meta: { name: '管理员' }
      },
      {
        path: '/powerManage/user/add',
        name: 'UserAdd',
        component: () => import('@/views/powerManage/user/addEdit.vue'),
        meta: { name: '新增管理员', hidden: true, activeMenu: '/powerManage/user/list' }
      },
      {
        path: '/powerManage/user/edit/:id?',
        name: 'UserEdit',
        component: () => import('@/views/powerManage/user/addEdit.vue'),
        meta: { name: '编辑管理员', hidden: true, activeMenu: '/powerManage/user/list' }
      },
      {
        path: '/powerManage/role/list',
        name: 'RoleList',
        component: () => import('@/views/powerManage/role/list.vue'),
        meta: { name: '角色管理' }
      },
      {
        path: '/powerManage/role/addEdit',
        name: 'RoleAdd',
        component: () => import('@/views/powerManage/role/addEdit.vue'),
        meta: { name: '新增', hidden: true, activeMenu: '/powerManage/role/list' }
      },
      {
        path: '/powerManage/role/addEdit/:id?',
        name: 'RoleEdit',
        component: () => import('@/views/powerManage/role/addEdit.vue'),
        meta: { name: '编辑', hidden: true, activeMenu: '/powerManage/role/list' }
      },
      {
        path: '/powerManage/menu/list',
        name: 'MenuList',
        component: () => import('@/views/powerManage/menu/list.vue'),
        meta: { name: '菜单管理', keepAlive: true }
      },
      {
        path: '/powerManage/menu/addEdit/:menuId?/:parentId?',
        name: 'MenuAdd',
        component: () => import('@/views/powerManage/menu/addEdit.vue'),
        meta: { name: '新增', hidden: true, activeMenu: '/powerManage/menu/list' }
      },
      {
        path: '/powerManage/menu/addEdit/:menuId?/:parentId?',
        name: 'MenuEdit',
        component: () => import('@/views/powerManage/menu/addEdit.vue'),
        meta: { name: '编辑', hidden: true, activeMenu: '/powerManage/menu/list' }
      }
    ]
  }
]
